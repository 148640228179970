import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Gmap } from "./gmap"
import './gallery.css'



export const galleryQuery = graphql`
  query GalleryQuery {  
    allContentfulAsset {
    edges {
      node {        
        localFile {
          childrenImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
}
`;

function createGallery(data) {
  let image

  return (
    <div className="masonry">
      {
        data.map((d, idx) => {
          image = getImage(d.node.localFile.childrenImageSharp[0])
          return image && (
            <div key={idx} className="item">
              <GatsbyImage image={image} alt="" />
            </div>
          )
        })
      }
    </div>
  )
}

export default function Gallery() {
  return (
    <StaticQuery
      query={galleryQuery}
      render={data => {
        return (
          <>
            <section className="px-4 mt-6 text-justify md:mx-auto md:max-w-7xl">
              {createGallery(data.allContentfulAsset.edges)}
            </section>
            <Gmap />
          </>
        )
      }}
    />
  )
}

